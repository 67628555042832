<template>
    <div class="contentDetail">
        <qy-top-nav :title="title" :noRight="true" :noLeft="true"/>
        <div style="padding:0 10px"  v-html="content" :style="{height:containerHeight,overflow:'auto'}"></div>
    </div>
</template>
<script type="text/javascript">
import request from '@/utils/request';
export default {
    name:"content",
    data() {
        return {
            title: "",
            content: "",
            noNav:true,
            containerHeight: 0
        }
    },
    created() {
        this.containerHeight = document.documentElement.clientHeight - 50 + "px";
        let code = this.getUrlParam("code")||"-1";
        if(code==-1){
            code="FEAYiSUhGMKbHeA";
        }else{
            this.noNav=false;
        }
        request.post("/api/page", { code: code }).then(rs => {
            if (rs.success) {

                this.title = rs.data.title;
                this.content = rs.data.content;
            } else {
                this.Toast(rs.msg);
            }
        })
    },
    methods: {

    }
}
</script>
<style>
.contentDetail img{
    max-width: 100%;
}
</style>